@import "variables";
@import "theme";
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");

/* You can add global styles to this file, and also import other style files */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  text-decoration: none !important;
}

html {
  font-size: 9px;
}

body {
  // min-height: 100vh;
  font-family: "Tajawal", sans-serif !important;
}

html,
body {
  // margin: -1px 0 0 0;
  margin: 0;
  // border-top: 1px solid;
  scrollbar-width: thin !important;
  scrollbar-color: #caa29a transparent !important;
}

::-webkit-scrollbar {
  width: 0.5rem;
  scrollbar-width: thin !important;
  scrollbar-color: #caa29a transparent !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2rem;
}

::-webkit-scrollbar-thumb {
  background: $secondry-color;
  border-radius: 2rem;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
  scrollbar-color: #caa29a transparent !important;
}

.field {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  font-weight: normal;
  margin-bottom: 1rem;

  &__label {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    height: fit-content;

    &__required {
      color: #c73136;
      font-size: 2rem;
    }
  }

  &.field-check,
  &.field-radio,
  &.field-boolean {
    margin-bottom: 2.5rem;
  }

  &__alert-message {
    color: #c73136;
    margin-top: 1rem;
  }

  mat-icon {
    color: #55636d;
    height: 3rem;
    width: 3rem;
    font-size: 3rem;
    position: relative;
    bottom: 0.5rem;
    cursor: pointer;

    &.active {
      color: $secondry-color;
    }
  }

  &.field-file {
    .upload-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 1.8rem;
        color: #55636d;
      }

      button {
        width: 15rem;
        height: 4rem;
        font-size: 1.6rem;
        color: #55636d;
        background-color: #f6f6f6;
        border-radius: 1rem;
      }
    }
  }

  &.field-date {
    .date-picker {
      height: 6rem;
      width: 100%;
      background-color: #f6f6f6;
      border-radius: 1.6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1.5rem;
      box-shadow: 0px 2px 6px #0000001a;

      p {
        font-size: 1.8rem;
        color: #8f8f8f;
      }

      mat-icon {
        position: relative;
        top: 0.15rem;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  html {
    font-size: 7px;
  }
}

@media only screen and (max-width: 1366px) {
  html {
    font-size: 7px;
  }
}

.disabled-feature {
  pointer-events: none;
  opacity: 0.6;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100rem;
}

.ozwah-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}

textarea {
  resize: none !important;
}
.hidden {
  overflow: hidden;
}

.latest-updates__wrapper_content_list_item__answer.you,
.latest-updates__wrapper_content_list_item__replay-wrap_info.you,
.inquiry-details__answer.you,
.complain-details__answer__desc {
  a.link {
    color: white !important;
  }
}
.latest-updates__wrapper_content_list_item__answer,
.latest-updates__wrapper_content_list_item__replay-wrap_info,
.inquiry-details__answer {
  a.link {
    text-decoration: underline !important;
    color: grey;
  }
}

.custome-scroll {
  height: 59vh;
  overflow-y: auto;
  margin: 1rem 0;
  padding: 0 0.5rem;
  scrollbar-width: none !important;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:hover {
    display: none !important;
  }
  @media only screen and (max-height: 670px) {
    height: 50vh;
  }
  @media only screen and (max-width: 768px) {
    height: auto !important;
    overflow: hidden !important;
  }
}

.material-symbols-outlined {
  font-family: "material symbols outlined" !important;
}

// Shared Tabs for lawyer cases pages

.lawyer-cases-tabs {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 5rem;
  .link {
    color: #000;
    cursor: pointer;
    font-size: 20px;
    position: relative;
    @media (max-width: 991px) {
      font-size: 16px;
    }

    &.active {
      position: relative;
      font-weight: bold !important;

      &.active::after {
        content: "";
        width: 50%;
        height: 3px;
        position: absolute;
        bottom: -25%;
        right: 25%;
        background-color: $secondry-color;
      }
    }

    &:hover:after {
      content: "";
      width: 50%;
      height: 3px;
      position: absolute;
      bottom: -25%;
      right: 25%;
      background-color: $secondry-color;
    }
  }
}
