@use "@angular/material" as mat;

@include mat.core();

$md-primarycolor: (
  50: #ebeced,
  100: #ccd0d3,
  200: #aab1b6,
  300: #889299,
  400: #6f7a83,
  500: #55636d,
  600: #4e5b65,
  700: #44515a,
  800: #3b4750,
  900: #2a353e,
  A100: #8bccff,
  A200: #58b5ff,
  A400: #259eff,
  A700: #0c93ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$md-secondrycolor: (
  50: #f9f4f3,
  100: #f0e4e2,
  200: #e7d2ce,
  300: #ddc0ba,
  400: #d5b3ac,
  500: #cea59d,
  600: #c99d95,
  700: #c2938b,
  800: #bc8a81,
  900: #b0796f,
  A100: #ffffff,
  A200: #fff9f8,
  A400: #ffcdc5,
  A700: #ffb8ac,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ozwah-frontend-primary: mat.define-palette($md-primarycolor);
$ozwah-frontend-accent: mat.define-palette($md-secondrycolor, 900, 800, 700);

// The warn palette is optional (defaults to red).
$ozwah-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ozwah-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $ozwah-frontend-primary,
      accent: $ozwah-frontend-accent,
      warn: $ozwah-frontend-warn,
    ),
  )
);

@include mat.all-component-themes($ozwah-frontend-theme);

::ng-deep {
  [dir="rtl"] .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 0 1.6rem 1.6rem 0 !important;
    min-width: 1.6rem !important;
    border-color: transparent !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    height: 6rem !important;
  }

  [dir="rtl"] .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-start {
    border-radius: 0 1.6rem 1.6rem 0 !important;
    min-width: 1.6rem !important;
    border-color: #cea59d !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 1.6rem 0 0 1.6rem !important;
    border-color: transparent !important;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-end {
    border-radius: 1.6rem 0 0 1.6rem !important;
    border-color: #cea59d !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    width: 0 !important;
    border-color: transparent !important;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-end {
    border-radius: 1.6rem 0 0 1.6rem !important;
    border-color: #cea59d !important;
  }

  .mat-form-field-label-wrapper {
    transform: translateX(-20px) !important;
    border-color: transparent !important;
  }

  .mat-form-field-label {
    transform: translateX(-20px) !important;
    border-color: transparent !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: #f6f6f6;
    border-radius: 1.6rem !important;
    box-shadow: 0px 2px 6px #0000001a;
    border-color: transparent !important;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
    background-color: white;
    border-radius: 1.6rem !important;
    box-shadow: 0px 2px 6px #f6f6f6;
    border-color: #cea59d !important;
    border-color: transparent !important;
  }

  .mat-select {
    font-family: "Tajawal", sans-serif !important;
  }

  .mat-select-placeholder,
  .mat-select-value,
  .mat-input-element {
    position: relative !important;
    top: -1.2rem !important;
    font-size: 1.4rem;
    font-weight: 500 !important;
    font-family: "Tajawal", sans-serif !important;
    box-sizing: border-box !important;
  }

  .mat-form-field-type-mat-select {
    .mat-select-placeholder,
    .mat-select-value,
    .mat-input-element {
      position: relative !important;
      top: -0.5rem !important;
      font-size: 1.6rem;
      font-weight: 500 !important;
      font-family: "Tajawal", sans-serif !important;
    }

    .mat-select-arrow-wrapper {
      position: relative !important;
      top: -0.5rem !important;
      font-weight: 500 !important;
      font-family: "Tajawal", sans-serif !important;
    }

    .mat-select-value,
    .mat-input-element {
      position: relative !important;
      top: -1rem !important;
      font-size: 1.6rem;
      font-weight: 500 !important;
      font-family: "Tajawal", sans-serif !important;
    }
  }

  .mat-datepicker-input {
    .mat-select-placeholder,
    .mat-select-value,
    .mat-input-element {
      position: relative !important;
      top: -0.8rem !important;
      font-size: 1.6rem;
      font-weight: 500 !important;
      font-family: "Tajawal", sans-serif !important;
    }

    .mat-select-arrow-wrapper {
      position: relative !important;
      top: -0.8rem !important;
      font-weight: 500 !important;
      font-family: "Tajawal", sans-serif !important;
    }

    .mat-select-value,
    .mat-input-element {
      position: relative !important;
      top: -1.8rem !important;
      font-size: 1.6rem;
      font-weight: 500 !important;
      font-family: "", sans-serif !important;
    }
  }

  .mat-datepicker-toggle {
    button {
      position: relative;
      top: -0.5rem;
    }
  }

  .mat-datepicker-toggle button {
    top: 0 !important;
    width: 100% !important;
    height: unset !important;
  }

  .mat-datepicker-content {
    margin-top: 1rem;
  }

  .mat-datepicker-content .mat-calendar-controls {
    margin: 0;
  }

  .mat-calendar-content,
  .mat-calendar-header {
    padding: 0 !important;
  }

  .mat-datepicker-content .mat-calendar-body-selected,
  .mat-calendar-body-cell-content.mat-focus-indicator {
    border-radius: 30% !important;
    border: none;
    font-size: 2rem;
    font-weight: 500;
    // color: #565252 !important;
  }

  .mat-calendar-table-header-divider {
    display: none;
  }

  [dir="rtl"] .mat-radio-label-content {
    padding-right: 1.5rem !important;
    font-family: "Tajawal", sans-serif !important;
  }

  mat-radio-group {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    row-gap: 2rem;
    font-family: "Tajawal", sans-serif !important;
  }

  .mat-radio-outer-circle {
    border-color: #cea59d !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #cea59d !important;
  }

  .mat-checkbox-inner-container {
    height: 2.4rem !important;
    width: 2.4rem !important;
    //font-family: "Tajawal", sans-serif !important;
  }

  .mat-checkbox-frame {
    border-color: #cea59d !important;
    //font-family: "Tajawal", sans-serif !important;
  }

  .mat-checkbox {
    margin: 1.5rem 0 !important;
    font-family: "Tajawal", sans-serif !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0.25rem !important;
  }

  [dir="rtl"] .mat-form-field-appearance-outline.ng-invalid.ng-touched .mat-form-field-outline-start {
    border-color: #c73136 !important;
  }

  .mat-form-field-appearance-outline.ng-invalid.ng-touched .mat-form-field-outline-end {
    border-color: #c73136 !important;
  }

  .mat-form-field-appearance-outline.ng-invalid.ng-touched .mat-form-field-outline-gap {
    border-color: #c73136 !important;
  }

  // .mat-form-field-type-mat-input {
  //   height: 6rem;
  //   width: 100%;
  //   background-color: #f6f6f6;
  //   border-radius: 1.6rem;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   padding: 0 1.5rem;
  //   box-shadow: 0px 2px 6px #0000001a;
  // }

  // .mat-form-field-flex{
  //   background-color: transparent !important;
  // }
  // .mat-form-field-underline{
  //   width: 0 !important;
  // }

  // .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper{
  //   display: none;
  // }

  .mat-datepicker-content {
    border-radius: 1.1rem !important;
    background-color: $white;

    .mat-calendar {
      height: fit-content !important;
      margin-bottom: 0.6rem;
    }

    .mat-calendar-table-header,
    .mat-calendar-previous-button::after,
    .mat-calendar-period-button,
    .mat-calendar-next-button::after {
      color: #55636d;
      font-weight: bold;
      font-size: 2rem;
      font-family: "Tajawal", sans-serif !important;
    }

    .mat-calendar-arrow {
      border-top-color: #55636d;
    }

    .mat-calendar-body-cell-content {
      color: #565252;
    }

    .mat-calendar-body-selected {
      background-color: $secondry-color;
      border-radius: 50%;
      color: white;
    }

    .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      border-color: $secondry-color;
    }

    .mat-calendar-controls {
      justify-content: space-around;
    }

    .mat-calendar-spacer,
    .mat-calendar-body-label {
      display: none;
    }

    .mat-calendar-previous-button {
      order: 1;
    }

    .mat-calendar-period-button {
      order: 2;
    }

    .mat-calendar-next-button {
      order: 3;
    }

    .mat-datepicker-actions {
      justify-content: space-around;

      button:not(.mat-raised-button) {
        color: $white;
      }

      button.mat-raised-button {
        font-family: "Tajawal", sans-serif !important;
        background-color: $primary-color;
        min-width: 7.3rem;
        height: 3rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      button.mat-button {
        font-family: "Tajawal", sans-serif !important;
        background-color: transparent;
        border: 1px solid $primary-color;
        color: $primary-color;
        min-width: 7.3rem;
        height: 3rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .success-snackbar {
    height: 100vh !important;
    width: 100vw !important;
    max-width: 100vw !important;
    min-width: 100vw !important;
    background-color: rgba($color: #171a26, $alpha: 0.7);
    margin: 0 !important;
    direction: ltr;

    &.mat-snack-bar-container {
      .mat-simple-snackbar {
        min-width: 40rem !important;
        width: auto !important;
        padding: 0 2rem;
        max-width: max-content !important;
        min-height: 11rem !important;
        height: auto !important;
        border-radius: 1rem !important;
        color: $white;
        background-color: $secondry-color;
        font-size: 3.2rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.6;
        font-family: "Tajawal", sans-serif !important;
        margin: 5rem auto;

        &::after {
          content: "";
          margin-inline-end: 2rem;
          object-fit: contain;
          min-width: 10rem;
          min-height: 10rem;
          height: auto;
          width: auto;
          background-image: url("./assets/images/snackbar-img.png");
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        .mat-simple-snack-bar-content {
          text-align: center;
          padding: 2rem;
        }
      }
    }
  }

  .error-snackbar {
    height: 100vh !important;
    width: 100vw !important;
    max-width: 100vw !important;
    min-width: 100vw !important;
    background-color: rgba($color: #171a26, $alpha: 0.7);
    margin: 0 !important;
    direction: ltr;

    &.mat-snack-bar-container {
      .mat-simple-snackbar {
        justify-content: space-evenly;
        min-width: 40rem !important;
        width: auto !important;
        max-width: max-content !important;
        min-height: 7rem !important;
        height: auto !important;
        border-radius: 1rem !important;
        color: white;
        background-color: #ab1212;
        font-size: 2.3rem !important;
        display: flex;
        align-items: center;
        line-height: 1.6;
        font-family: "Tajawal", sans-serif !important;
        margin: 5rem auto;

        .mat-simple-snack-bar-content {
          text-align: center;
          padding: 2rem;
        }
      }

      .mat-simple-snackbar-action {
        cursor: pointer;

        span {
          font-size: 3rem;
          color: white;
          font-weight: 400;
        }
      }
    }

    // &.mat-snack-bar-container {
    //   width: fit-content !important;
    //   min-height: 2.5rem !important;
    //   max-width: 50rem;
    //   border-radius: 1rem !important;
    //   padding: 0.5rem 1rem !important;
    //   color: $white;
    //   font-size: 2rem !important;
    //   background-color: $primary-color;
    //   max-width: none !important;
    //   border-left: 1rem solid #c73136;

    //   .mat-simple-snack-bar-content {
    //     font-size: 2rem !important;
    //     font-family: "Tajawal", sans-serif !important;
    //     display: flex;
    //     align-items: center;
    //     justify-content: flex-start;
    //     height: 100%;

    //     &::before {
    //       font-family: "Material Icons";
    //       content: "hide_source";
    //       -webkit-font-feature-settings: "liga";
    //       margin-inline-end: 1rem;
    //     }
    //   }
    // }
  }

  .white-dialog {
    .mat-dialog-container {
      border-radius: 2rem !important;
      box-shadow: 0px 5px 80px #00000080 !important;
    }
  }

  .secondry-dialog {
    .mat-dialog-container {
      border-radius: 2rem !important;
      box-shadow: 0px 5px 80px #00000080 !important;
      background-color: $secondry-color;
      color: white;
      font-size: 3.2rem !important;
    }
  }

  .confirm-dialog {
    .mat-dialog-container {
      border-radius: 2rem !important;
      box-shadow: 0px 5px 80px #00000080 !important;
    }
  }

  .attachment-dialog {
    .mat-dialog-container {
      border-radius: unset !important;
      box-shadow: none !important;
      background-color: rgba(0, 0, 0, 0.7) !important;
      width: 100vw !important;
      height: 100vh !important;
      max-width: 100% !important;
      max-height: 100% !important;
      display: flex;
      align-items: center;
      overflow: hidden;
      justify-content: center;
    }
  }

  .mat-dialog-container {
    border-radius: 2rem !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-resizer {
    display: none;
  }

  .mat-button-disabled {
    color: #ffffff !important;
  }

  .mat-button-toggle {
    border-left: none !important;
    border-right: none !important;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 40px !important;
  }

  .mat-button-toggle .mat-button-toggle-ripple {
    border-radius: 50%;
  }

  ::-moz-selection,
  ::selection {
    color: $white !important;
    background: $secondry-color !important;
  }

  .tooltip-green {
    background: #0f996d !important;
    color: $white !important;
    font-size: 1.6rem;
    padding: 0.5rem 2rem;
    height: 4rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .tooltip-grey {
    // background-color: $white;
    // color: #969797;
    font-size: 1.6rem;
    color: $white;
  }

  .tooltip-silver {
    background-color: #969797;
    color: $white;
    font-size: 1.6rem;
  }

  .tooltip-more {
    // background-color: transparent;
    color: #969797;
    font-size: 1.6rem;
  }

  .mat-menu-item {
    padding: 0 1.6rem !important;
  }

  .mat-menu-panel {
    min-height: fit-content !important;
    max-height: fit-content !important;
    min-width: 20rem !important;
    max-height: fit-content !important;
    @media only screen and (max-width: 992px) {
      border-radius: 10px;
      box-shadow: 0px 3px 6px #00000029;
    }
  }

  .mat-menu-item {
    height: fit-content !important;
    padding: 0.75rem 1rem !important;
    line-height: 3rem !important;
    @media only screen and (max-width: 992px) {
      padding: 1rem 0 !important;
    }
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    @media only screen and (max-width: 992px) {
      padding: 10px;
    }
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
